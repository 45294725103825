import { useRef } from 'react'
import { useToast } from 'shared/hooks'
import { useDeleteCentralRefused } from 'services/centralRefused'
import { ButtonV2, Icon, ModalV2 } from 'components'

interface DeleteCentralProps {
  centralId: string
}

const DeleteCentral = ({ centralId }: DeleteCentralProps) => {
  const { addToast } = useToast()
  const closeRef = useRef<HTMLButtonElement>(null)
  const { mutate, isPending } = useDeleteCentralRefused()

  const onRemove = () => {
    mutate(centralId, {
      onSuccess: () => {
        addToast({
          message: 'GPRS/Ethernet removido com sucesso.',
          type: 'success',
        })

        if (closeRef.current) {
          closeRef.current.click()
        }
      },
      onError: () => {
        addToast({
          message: 'Erro ao remover GPRS/Ethernet. Tente novamente.',
          type: 'alert',
        })
      },
    })
  }

  return (
    <>
      <ModalV2.Title>
        <Icon name="warning" color="accent-yellow-default" />
        Remover módulo GPRS/Ethernet da lista
      </ModalV2.Title>
      <p>
        Ao remover, o módulo não poderá mais ser habilitado. Para restaurá-lo,
        será necessário reconectá-lo novamente.
      </p>
      <ModalV2.Footer>
        <ModalV2.Close>
          <ButtonV2 appearance="tertiary" ref={closeRef}>
            Cancelar
          </ButtonV2>
        </ModalV2.Close>
        <ButtonV2 color="red" onClick={() => onRemove()} disabled={isPending}>
          Remover
        </ButtonV2>
      </ModalV2.Footer>
    </>
  )
}

export default DeleteCentral
